import Modal from "../../components/Modal/Modal"
import styles from "./inviteModal.module.scss";
import Text from '../../components/Text';
import Button from "../../components/Button";
import { ReactComponent as Background } from '../../assets/background.svg';

import React from "react";

interface IInviteModal {
  isOpen: boolean;
  handleClose: (status: boolean) => void;
  familyData: any;
}

const InviteModal: React.FC<IInviteModal> = ({
  isOpen,
  handleClose,
  familyData,
}) => {
  const [finalStep, setFinalStep] = React.useState(false);

  return (
    finalStep ?
      <Modal
        handleClose={() => {
          handleClose(false);
          setFinalStep(false);
        }}
        isOpen={isOpen}
      >
        <div className={styles.wrapper}>
          <div className={styles.contentWrapper}>
            <Text type='header'>Something to Look Forward to: Thanksgiving Point has Invited You to Schedule a Play Date, and We'll Provide the Tickets.
            </Text>
            <Text textAligin='left' margin="8vh 0 0 0">
              Join Us for a Special Can You Play Playdate at Thanksgiving Point!
              Can You Play families, we have a treat for you! We're excited for you to come togehter with the
              {` ${familyData?.family_name}`} family for an unforgettable day of fun, laughter, and connection at Thanksgiving Point.
            </Text>
            <Text margin="4vh 0 0 0">
              Claim your **FREE museum tickets** and experience the wonders of
              Natural History Museum of Utah. It's the perfect opportunity for your family to bond,
              explore, and create cherished memories with other Can You Play participants.
            </Text>

            <Text margin="4vh 0 0 0">
              Spaces are limited, so be sure to reserve your tickets today!
            </Text>        
          </div>
           
        </div>
      </Modal> :
      <Modal
        handleClose={() => {
          handleClose(false);
          setFinalStep(false);
        }}
        isOpen={isOpen}
      >
        <div className={styles.blankWrapper}>
          <div style={{ width: '100%' }}>
            <h1 style={{ color: 'white', textAlign: "center" }}>Schedule Your Play Date</h1>
          </div>
          <Background width='100%' height='100%' />
          <div style={{ position: 'absolute', right: '12vw', bottom: '10vh' }}>
            <Button width="160px" type="confirm">Schedule</Button>
          </div>
          <div
            onClick={() => {
              setFinalStep(true);
            }}
            style={{ position: 'absolute', right: '5px', bottom: '5px' }}>
            <h2 style={{ cursor: 'pointer', color: 'red' }}>[see short-term alternative]</h2>
          </div>
        </div>
      </Modal>
  )
}

export default InviteModal;