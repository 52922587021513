
const STORAGE_KEY = "answers";


const initialValue = [
  {
    id: 0,
    type: 'text',
    value: 'What is your child’s age?',
    answer:''
  },
  {
    id: 1,
    type: 'select',
    options: ['84124'],
    value: 'Zip Code',
    answer:''
  },
  {
    id: 2,
    type: 'select',
    options:['Weekdays', 'Weekends'],
    value: 'Which is better for a playdate?',
    answer:''
  },
  {
    id: 3,
    type: 'select',
    options: ['Mornings', 'Midday', 'Evenings'],
    value: 'What are the best times your family is available?',
    answer:''
  },
  {
    id: 4,
    type: 'select',
    options: [
      'Autism Spectrum Disorder (ASD)',
      'Social Interaction Difficulties',
      'Communication Challenges',
      'Repetitive Behaviors and Restricted Interests',
      'Sensory Sensitivities',
      'Cognitive and Learning Differences',
      'Emotional Regulation Difficulties',
      'Play Differences',
      'Motor Skill Challenges',
      'Unusual Eating or Sleeping Habits',
      'Attention to Detail',
      'Neuro Typical'
    ],
    value: 'Developmental Traits',
    answer:''
  },
  {
    id: 5,
    type: 'select',
    options:[
      '0-6 months',
      '6 months -1 yr',
      '1 yr - 2 yr',
      '2-4 years',
      '5+ years'
    ],
    value: 'How long has it been since your child was diagnosed?',
    answer:''
  },
  {
    id: 6,
    type: 'select',
    options: [
      'Roblox/Minecraft',
      'Fantasy Games',
      'Racing Video Games',
      'Peppa Pig',
      'Paw Patrol',
      'Animals/Zoo',
      'Play outdoors/Bubbles',
      'Books',
      'Pokemon'
    ],
    value: 'What is your child’s favorite activities and interests?',
    answer:''
  },
  {
    id: 7,
    type: 'text',
    value: 'Are there specific challenges your child faces during play or social interactions?',
    answer:''
  },
  {
    id: 8,
    type: 'select',
    options:[      
      'Improving motor skills',
      'Looking for friends',
      'Looking for mentorship',
      'Enhancing social skills'
    ],
    value: 'Do you have any goals for your child’s playtime?',
    answer:''
  },  
  {
    id: 9,
    type: 'text',
    value: 'Anything else you might want to share about your family?',
    answer:''
  }   
];

export const saveData = (data: object): void => {
  localStorage.setItem(STORAGE_KEY, JSON.stringify(data));
};

export const loadData = (): object | null => {
  const data = localStorage.getItem(STORAGE_KEY);
  return data ? JSON.parse(data) : null;
};

export const updateData = (idx: number, value: any): void => {
  const currentData: any = loadData() || {};
  currentData[idx].answer = value;
  saveData(currentData);
};

export const deleteData = (key: string): void => {
  const currentData: any = loadData();
  if (currentData && key in currentData) {
    delete currentData[key];
    saveData(currentData);
  }
};

export const clearData = (): void => {
  localStorage.removeItem(STORAGE_KEY);
};


export const setData = (): any => {
  localStorage.setItem(STORAGE_KEY, JSON.stringify(initialValue));
  return(initialValue)
}