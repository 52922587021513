import Chatbot from 'react-chatbot-kit';
import 'react-chatbot-kit/build/main.css'
import config from './config';
import MessageParser from './MessageParser';
import ActionProvider from './ActionProvider';
import './styles.scss';
import React, { useCallback } from 'react';
import Text from '../../components/Text';
import Button from '../../components/Button';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import { loadData } from '../../Storage';
import { useGlobalState } from '../../AppState';
import { useViewWidth } from '../../hooks/useViewWidth';
import { ReactComponent as LogoOneLine } from '../../assets/logo_one_line.svg';

interface ICustonChatbot {
  sidepaneOpen: boolean;
  mapOn: boolean;
  setMap: (state: boolean) => void;
  initialPrompt: boolean;
  setInitialPrompt: (val: boolean) => void;
}

const CustomChatbot: React.FC<ICustonChatbot> = ({ 
  mapOn, 
  setMap, 
  initialPrompt,
  setInitialPrompt
}) => {
  const [formEnded, setFormEnded] = React.useState(false);
  const [questions, setQuestions] = React.useState<any>([]);
  const { currentIdx, setCurrentIdx } = useGlobalState();
  const viewWidth = useViewWidth();
  const btnWidth = (viewWidth < 480)?'70vw':'400px'
  React.useEffect(() => {
    let data = loadData()
    if(data)
      setQuestions(data as any)
  }, []);

  const openPaper = () =>{
    window.open('https://onedrive.live.com/?authkey=%21ACYUaMOyesczf7A&id=02E04EC14EFE023A%214891&cid=02E04EC14EFE023A&parId=root&parQt=sharedby&o=OneUp', '_blank', 'noopener,noreferrer');
  }
  
  const generateInitialPrompt = () => {
    return (
      <div className={'contentWrapper'}>
        <div className='logoWrapper'>
          <Logo width='110px' />
        </div>
        <Text textAligin='center' type='small' >Empowering connection </Text>
        <Text textAligin='center' type='small'>through play</Text>

        <div className='btnsWrapper'>
          <Button onClick={() => { setInitialPrompt(false) }} type='secondary' width={btnWidth}>Let's Get Started</Button>
          {/* <Button type='secondary' width={btnWidth}>Tell me more about this</Button>
          <Button type='secondary' width={btnWidth}>Who is this for?</Button> */}
          <Button onClick={openPaper} type='secondary' width={btnWidth}>Tell Me About the Academic Research</Button>
          <Button type='secondary' width={btnWidth}>Chat with a Trusted Expert</Button>

        </div>
        <div className='bgCircleOne'></div>
        <div className='bgCircleTwo'></div>
        <div className='bgCircleThree'></div>
      </div>
    );
  }

  const restartForm =() => {
    window.location.reload();
  }

  const gotoMap = () => {
    setMap(true);
  }

  const onFormEnd = React.useCallback(() => {
    setFormEnded(true);
  }, []);

  const memoizedActionProvider = useCallback(
    (props: any) => ActionProvider({ ...props, onFormEnd, currentIdx, setCurrentIdx }),
    [onFormEnd]
  );
  
  return(
    <>
      {initialPrompt ?
        generateInitialPrompt() :
        <div 
          onKeyDown={(e) => {
            e.stopPropagation()
            if(e.code === 'Tab') {
              e.stopPropagation()
              e.preventDefault()
              const elements = document.getElementsByClassName('react-chatbot-kit-chat-input');
              const firstElement = elements[0];
              if(firstElement){
                (firstElement as any).value = questions[currentIdx-1]?.answer;
              }
            }
          }}
          className={mapOn ? 'hidden' : 'contentWrapper'}>
          <div className='chatHeader'>
            <LogoOneLine className='oneLineLogo'/>
            <div className='line'/>
          </div>
          <Chatbot
            placeholderText={questions[currentIdx-1]?.answer ?? 'Send message'}
            config={config as any}
            messageParser={MessageParser}
            actionProvider={memoizedActionProvider}
          />
          <div className='bgCircleOne'></div>
          <div className='bgCircleTwo'></div>
          <div className='bgCircleThree'></div>
         
          {(formEnded) &&
            <div id="chatbot-bottom-btns" className='footerButtons'>
              <Button 
                width={(viewWidth < 480)?'30vw':'10vw'}
                onClick={restartForm}
                type='secondary'>Start Over</Button>
              <Button 
                width={(viewWidth < 480)?'30vw':'10vw'}
                onClick={gotoMap}
                type='confirm'>Find Matches</Button>
            </div>
          }
        </div>
      }
    </>
  )
}

export default CustomChatbot;