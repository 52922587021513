
import styles from './sidepane.module.scss';
import Text from '../../components/Text';
import Button from '../../components/Button';
import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { ReactComponent as Picture } from '../ProfilePage/familyPic.svg';
import { useLocation, useNavigate } from 'react-router-dom';

const SidepaneContent: React.FC = () => {

  const { logout } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const mapOn = queryParams.get('map') === 'true';


  const doLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } })
  }

  const toogleMap = () => {
    navigate(`/home?map=${!mapOn}`);
  }

  const navigateProfile = () => {
    navigate(`/profile`);
  }

  return (
    <div className={styles.contentWrapper}>
      <div className={styles.header}>
        <Text textAligin='center' bold>Can You Play</Text>
      </div>
      <div className={styles.row
      }>
        <Picture className={styles.picture}/>
        <div className={styles.column}>
          <Text type='subHeader'>Rappleye Family</Text>
          <Text type='small'>LEHI, UT</Text>
        </div>
      </div>
      <div className={styles.listWrapper}>
        <Button type='transparent' onClick={navigateProfile}>Profile Settings</Button>
        <Button type='transparent'>About Can You Play</Button>
        <Button onClick={toogleMap} type='transparent'>{mapOn? 'Navigate Home': 'See Matches'}</Button>

      </div>
      <div className={styles.divider} />
      <div className={styles.listWrapper}>
        <Button 
          onClick={doLogout}
          type='transparent'>Log Out</Button>
      </div>
    </div>
  )
}

export default SidepaneContent;