import styles from './profilepage.module.scss'
import Text from '../../components/Text'
import Button from '../../components/Button'
import { ReactComponent as FamilyPic } from './familyPic.svg';
import { ReactComponent as Face } from '../../assets/face.svg';
import { ReactComponent as Play } from '../../assets/play_circle.svg';
import { ReactComponent as Send } from '../../assets/send_black.svg';
import { ReactComponent as Image } from '../../assets/image.svg';
import { ReactComponent as ArrowBack } from '../../assets/arrow_back.svg';

import Slider from '../../components/Slider';
import { useNavigate } from 'react-router-dom';


const ProfilePage = () => {

  const navigate = useNavigate();
  const navigateHome = () => {
    navigate("/home");
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.circle}/>
      <div 
        onClick={navigateHome}
        className={styles.backBtn}>
        <ArrowBack style={{padding:0, margin:0}}/>
      </div>
      <div className={styles.wrapperContent}>

        <div className={styles.header}>
          <div className={styles.columCentered}>
            <div className={styles.familyPic}>
              <FamilyPic width={300} height={300}/>
            </div>
            <div className={styles.rowCentered}> 
              <Play fill='black' className={styles.icon}/>
              <Image fill='black' className={styles.icon}/>
              <Send className={styles.icon}/>
            </div>
          </div>
          <div>
            <Text margin='20px 0 0 0' type='huge' color='white'>Meet the Rappleyes!</Text>
            <div className={styles.row}>
              <Text type='big' color='white' width='auto' margin='0 10px 0 0'>Lehi, UT</Text>
              <Face className={styles.icon} fill='#FF50E0'/>
              <Face className={styles.icon}  fill='#4903E2'/>
            </div>
            {/* kids icons */}
            <Text type='bigItalic' color='white'>Hello from Anna, Steve, Ben and Sarah Rappleye!</Text> 
          </div>
        </div>
        <div className={styles.btnWrapper}>
            <Button type='pressme'><Text type='headerWithShaddow' color='white' fontWeight={1000} >CAN YOU PLAY?</Text></Button>
        </div>

        <div className={styles.contentWrapper}>
          <div className={styles.column1}>
            <Text type='big' color='white' >About Us</Text>
            <Text fontSize={24} fontWeight={300} color='white'>
              We're the Rappleyes - Anna, Steve, Ben (6), and Sarah (3). Our son, Ben, is 6 years old and lives with ADHD and
              sensory processing challenges. He's full of energy, and loves building LEGO cities, listening to fun music, and exploring nature trails.
              Ben is happiest when he's climbing, running, or finding hidden "treasures" outdoors.
            </Text>
            <Text margin='20px 0 0 0' fontSize={24} fontWeight={300} color='white'>
              We're looking for families who enjoy meetups at parks or paygrounds where kids can engage in parallel play and casual outdoor activities.
            </Text>
            <Text margin='20px 0 0 0'  fontSize={24} fontWeight={300} color='white'>
              We want you to know that we're a warm, welcoming bunch who value friendly chats,
              shared experiences, and creating a safe space for everyone to have fun at their own pace.
              We're excited to make new connections and find friends who undestand that play can come in many forms
            </Text>
            <Text type='big' color='white' margin='30px 0 0 0'>Preferred Play Environments</Text>
            <Text fontSize={24} color='white' fontWeight={300}>
              Because of Rosie's needs we prefer to play in environments where there are ADA compliant
            </Text>
          </div>
          <div className={styles.column2}>
            <Text textAligin='right' type='header' color='white' fontSize={40} margin='20px 0 20px 0' fontWeight={1000}>Rosie's Traits</Text>
            <div className={styles.slidersWrapper}>
              <Slider labelLeft='Non-Verbal' labelRight='Verbal'/>
              <Slider labelLeft='Light Sensitivity' />
              <Slider labelLeft='Personal Space' />
              <Slider labelLeft='Tactile Play' />
            </div>
            <Text textAligin='right' type='header' color='white' fontSize={40} fontWeight={1000}>Favorite Locations</Text>
            <Text fontSize={24} fontWeight={300} color='white' >
              Thanksgiving Point's Butterfly Museum
            </Text>
          </div>
        </div>
       
      
      </div>
    </div>
  )
}

export default ProfilePage