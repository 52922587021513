import Header from "../../components/Header";
import styles from "../../App.module.scss";
import CustomChatbot from "../../Modules/Chatbot/Chatbot";
import SidePane from "../../components/SidePane";
import SidepaneContent from "../../Modules/Sidepane";
import React from "react";
import BackgroundComponent from "../../components/BackgroundComponent";
import MatchModal from "../../Modules/MatchModal";
import LoadingModal from "../../Modules/LoadingModal";
import apiService from "../../services/apiService";
import InviteModal from "../InviteModal";
import { useLocation, useNavigate } from "react-router-dom";

import { loadData } from '../../Storage';
import ChatModal from "../ChatModal/ChatModal";

function Home() {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [detailsModalOpen, setDetailsModalOpen] = React.useState<boolean>(false);
  const [inviteOpen, setInviteOpen] = React.useState<boolean>(false);
  const [chatModalOpen, setChatModalOpen] = React.useState<boolean>(false);
  const [familiesData, setFamiliesData] = React.useState([]);
  const [selectedFamily, setSelectedFamily] = React.useState<number>(0);
  const [initialPrompt, setInitialPrompt] = React.useState(true);

  const [loadingModalOpen, setLoadingModalOpen] = React.useState<boolean>(false);
  const { postData } = apiService(process.env.REACT_APP_EAI_URL ?? '');
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const mapOn = queryParams.get('map') === 'true';

  React.useEffect(() => {    
    getTableData();
    setInitialPrompt(true)
  }, [mapOn]);


  const togglePane = () => {setIsOpen(!isOpen)};
  const closeModal = (status:boolean) => {
    setDetailsModalOpen(false);
    setChatModalOpen(true);
    // if(status) {
    //   setInviteOpen(true);
    // }
  };
  const closeLoadingModal = () => {
    setLoadingModalOpen(false);
  };

  const mapSetter = () => {
    navigate("/home?map=true");
    setLoadingModalOpen(true);
  }

  const getTableData = async () => {
      
      const data:any = loadData();
      let json_param : any = {
        "limit": 5,
        "data_table_idx": 904,
        "table": [],          
        "row_id": [],
        "conditions": []
      };
      
      if (data != undefined) {
        //console.log("data=", data);
        //console.log("age=", data[0].answer);  // age 

        let row_ids : Array<number> = [];

        // Archetype 1: looking for common play
        if(data[8].answer === "Improving motor skills" || data[8].answer === "Looking for friends") {
          // Archetype 1
          //console.log("Archetype 1");

          if(data[6].answer === "Roblox/Minecraft") {
            if(data[0].answer < 10) {
              //console.log("This is for kids younger than 10");
              row_ids = [4151, 4481, 4559, 4579, 4612];
            } else {
              row_ids = [402, 4044, 4241, 4294, 4346];
            }
  
            json_param = {
              "limit": 5,
              "data_table_idx": 904,
              "table": [],          
              "row_id": row_ids
            };
          } else {
            //console.log("Archetype 1 Else condition: Matching on interest & availability alone");

            json_param = {
              "limit": 5,
              "data_table_idx": 904,
              "table": [],
              "conditions": [
                {
                  "operation": "=",
                  "logical": "or",
                  "variable": 10,  // kids_interest in the dataset
                  "value": data[6].answer   // kids_interest in the questionnaire
                }                
              ]
            };
          }

        } else if (data[8].answer === "Looking for mentorship") {
          // Archetype 2: looking for mentors
          //console.log("Archetype 2: looking for mentorship");
          json_param = {
            "limit": 5,
            "data_table_idx": 904,
            "table": [],
            "conditions": [
              {
                "operation": "=",
                "logical": "or",
                "variable": 9,
                "value": "Looking for mentorship"
              }
            ]
          };

        } else if (data[8].answer === "Enhancing social skills") {
          // Archetype 3: looking for neurotypical kids
          //console.log("Archetype 3: neurotypical");
          json_param = {
            "limit": 5,
            "data_table_idx": 904,
            "table": [],
            "conditions": [
              {
                "operation": "=",
                "logical": "and",
                "variable": 9,
                "value": "Enhancing social skills"
              }
            ]
          }

        } else {
          //console.log("Calling all else condition")
          row_ids = [1, 2, 3, 4, 5];
          json_param = {
            "limit": 5,
            "data_table_idx": 904,
            "table": []          
          }
        }          

      } else {
        json_param = {
          "limit": 5,
          "data_table_idx": 904,
          "table": []          
        }

      }

      const result = await postData('/api/e/get_raw_data', json_param).catch((err) => {
        console.log(err)
      });

      const families = result.current_levels.family_id.map((data: any, index: string | number) => {
        return {
          family_id: result.current_levels?.family_id[index],
          family_name: result.current_levels?.family_name[index],
          city: result.current_levels?.city[index],
          state: result.current_levels?.state[index],
          parent1_fname: result.current_levels?.parent1_fname[index],
          parent2_fname: result.current_levels?.parent2_fname[index],
          avail_tod: result.current_levels?.avail_tod[index],
          avail_week: result.current_levels?.avail_week[index],
          kids_description: result.current_levels?.kids_description[index],
          goal: result.current_levels?.goal[index],
          kids_interest: result.current_levels?.kids_interest[index],
          detail_description: result.current_levels?.detail_description[index],
          latitude: result.current_levels?.latitude[index],
          longitude: result.current_levels?.longitude[index],
          kids_array: result.current_levels?.kids_array[index],
          pic_url: result.current_levels?.pic_url[index]
        };
      });
      setFamiliesData(families);
  }

  const selectedFamilyCarousel = (direction: string) => {
    if(direction === 'r'){
      setSelectedFamily((selectedFamily < familiesData.length -1)? selectedFamily +1 : 0);
    } else if (direction === 'l'){
      setSelectedFamily((selectedFamily > 0)? selectedFamily -1 : familiesData.length -1);
    }
  }
  
  return(
    <div className={styles.App}>
      <SidePane 
        togglePane={togglePane}
        isOpen={isOpen}
        sidepaneContent={SidepaneContent}>
          <div className={styles.container}>
            <Header 
              isOpen={isOpen}
              mapOn={mapOn}
              togglePane={togglePane}
            />
            <BackgroundComponent 
              mapOn={mapOn} 
              familiesData={familiesData}
              setModalOpen={setDetailsModalOpen}
              onLoad={closeLoadingModal}
              selectedFamily={selectedFamily}
              selectedFamilyCarousel={selectedFamilyCarousel}
            >
             {(!mapOn) && 
              <div className={styles.contentWrapper}>
                <CustomChatbot 
                  initialPrompt={initialPrompt} 
                  setInitialPrompt={setInitialPrompt}                
                  setMap={mapSetter}
                  mapOn={mapOn}
                  sidepaneOpen={isOpen}
                />
              </div>
             }
              <LoadingModal isOpen={loadingModalOpen} handleClose={() => setLoadingModalOpen(false)}/>
              <MatchModal 
                selectedFamily={selectedFamily}
                selectedFamilyCarousel={selectedFamilyCarousel}
                isOpen={detailsModalOpen} 
                data={familiesData[selectedFamily ?? 0]}
                handleClose={closeModal}/>
              <InviteModal
                familyData={familiesData[selectedFamily]} 
                isOpen={inviteOpen} handleClose={() => setInviteOpen(false)}/>
              {chatModalOpen &&
                <ChatModal
                  callback={() => {
                    setInviteOpen(true)
                    setChatModalOpen(false)
                  }}
                  isOpen={chatModalOpen}
                  handleClose={() => setChatModalOpen(false)}
                />
              }

            </BackgroundComponent>
          </div>
      </SidePane>
    </div>
  );
}

export default Home;
